<template>
  <div id="page-content">
    <div class="container cabinet">
      <div class="container cabinet__presentation">
        <section class="row py-3 cabinet__presentation__content">
          <div class="col-12 col-lg-8">
            <div
              class="
                m-2 m-lg-4
                p-2
                rounded
                text-light
                cabinet__presentation__content__text
              "
            >
              <h1>Le cabinet d'orthoptie</h1>
              <dl>
                <dt>La structure</dt>
                <dd>
                  Mme Barès a commencé son activité libérale d'orthoptiste à
                  Tournefeuille en 2008.<br />
                  Le cabinet d’orthoptie est dans un complexe
                  multi-professionnel, proche du centre-ville de Tournefeuille.
                </dd>
                <dt>Les examens</dt>
                <dd>
                  Les orthoptistes vous y accueillent sur ordonnance médicale
                  afin de détecter les troubles responsables de symptômes
                  visuels, binoculaires ou fonctionnels.
                </dd>
                <dt>Les équipements</dt>
                <dd>
                  Le centre orthoptique possède par conséquent le matériel
                  approprié à la réalisation des examens.
                </dd>
              </dl>
            </div>
          </div>
          <div class="col-12 col-lg-4 text-center my-lg-auto">
            <img
              src="../assets/img/cabinet-cabinet.jpg"
              alt="Le cabinet d'orthoptie Barès"
              class="img-fluid rounded shadow-sm"
            />
          </div>
        </section>
      </div>
      <div class="container bg-light cabinet__praticiens">
        <div class="row cabinet__praticiens__title">
          <div class="col-12 pt-1 mx-auto text-center">
            <h2 class="d-inline-block m-3 cabinet__praticiens__title__heading">
              L'orthoptiste
            </h2>
          </div>
        </div>
        <section
          class="
            row
            cabinet__praticiens__content
            d-flex
            justify-content-around
            py-lg-4
          "
        >
          <div class="col-12 col-md-6 col-lg-4 p-3 d-flex">
            <div class="card shadow">
              <img
                class="card-img-top"
                src="../assets/img/cabinet-camille.jpg"
                alt="Camille BARES"
              />
              <div class="card-body">
                <h5 class="card-title">Camille Barès</h5>
                <p class="card-text">
                  <em>Titulaire du cabinet</em><br />
                  Originaire de Toulouse et diplômée d’état depuis 2008 de
                  l’école d’orthoptie de Toulouse.<br />
                  Mme Barès vous accueille du lundi au mercredi de 8h20 à 20h et
                  le jeudi de 8h20 à 13h40.
                </p>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">D.C.P Réfraction subjective</li>
                <li class="list-group-item">D.U Troubles des apprentissages</li>
                <li class="list-group-item">D.U Basse Vision</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <div class="row my-4">
        <Carousel keyCarousel="cabinet" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Carousel from "../components/Carousel";

export default {
  name: "Cabinet",
  mounted() {
    window.scrollTo(0, 0);
    this.updateActive(this.$route);
    document.title = "Camille Barès - Le cabinet";
  },
  methods: {
    ...mapActions(["updateActive"]),
  },
  components: {
    Carousel,
  },
};
</script>

<style lang="scss" scoped>
.card-img-top {
  height: 250px;
  object-fit: cover;
  object-position: top;
}
</style>